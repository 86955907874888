<template>
<!-- changes = potentially adding calendar feature to select time -->
<v-card tile color='white'>
    <v-container>
        <v-btn plain tile text :ripple='false' @click='close'><font-awesome-icon icon='fa-solid fa-xmark' size='2xl' class='fa-fw'/></v-btn>
    </v-container>
    <v-container v-if='sent'>
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
            <circle class="path circle" fill="none" stroke="#4CAF50" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
            <polyline class="path check" fill="none" stroke="#4CAF50" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
        </svg>
        <h2 class='centered md-size'>Success!</h2>
        <p class='centered p-md grey--text mr-eaves'>We can&#8217;t wait to meet you!</p>
    </v-container>
    <v-container v-else-if='error'>
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
            <circle class="path circle" fill="none" stroke="#EF9A9A" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
            <line class="path line" fill="none" stroke="#EF9A9A" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3"/>
            <line class="path line" fill="none" stroke="#EF9A9A" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2"/>
        </svg>
        <h2 class='centered'>Uh Oh!</h2>
        <p class='centered p-sm grey--text mr-eaves'>An error occurred. Please try again in a few minutes or email us directly.</p>
    </v-container>
    <v-container v-else>
        <v-card-text class='mr-eaves p-sm'>
            <h2 class='andasia font-weight-regular md-lg-size centered lh-08 red--text text--lighten-3'>Let's get starte<strong class='font-weight-regular andasia-swash'>d</strong>!</h2>
            <br>
            <br>
            <form ref='form' @submit.prevent='sendEmail'>
            <h3>Contact Info</h3>
            <br>
            <v-row>
                <v-col cols='12' sm='6'>
                    <v-text-field label='First Name*' v-model='firstName' :rules='textFieldRules' required style='padding-top: 0px;'></v-text-field>
                </v-col>
                <v-col cols='12' sm='6'>
                    <v-text-field label='Last Name*' v-model='lastName' :rules='textFieldRules' required style='padding-top: 0px;'></v-text-field>
                </v-col>
                <v-col cols='12' sm='6'>
                    <v-text-field label='Phone*' v-model='phone' :rules='phoneRules' required style='padding-top: 0px;'></v-text-field>
                </v-col>
                <v-col cols='12' sm='6'>
                    <v-text-field label='Email*' v-model='email' :rules='emailRules' required style='padding-top: 0px;'></v-text-field>
                </v-col>
            </v-row>
            <v-checkbox v-model='previousDesigner' label='I have worked with an interior designer before.*' color='accent'></v-checkbox>
            <!-- <h3>This project is:*</h3>
            <v-radio-group row v-model='projectType' mandatory>
                <v-radio v-for="type in types" :key='type' :label="`${type}`" :value='type' color='accent'></v-radio>
            </v-radio-group>
            <h3>and is planned to be a(n):*</h3>
            <v-radio-group row v-model='projectSubtype' mandatory>
                <v-radio v-for="subtype in subtypes" :key='subtype' :label="`${subtype}`" :value='subtype' color='accent'></v-radio>
            </v-radio-group> -->
            <h3>Project Type*</h3>
            <v-select v-model='projectType' placeholder='Select' :items="['Home Refresh', 'Home Renovation', 'New Build']" required :rules='textFieldRules'></v-select>
            <br>
            <h3>Estimated Timeline to Completion*</h3>
            <v-select v-model='projectTimeline' placeholder='Select' :items="['6-12 months', '12-18 months', '18+ months']" required :rules='textFieldRules'></v-select>
            <br>
            <v-textarea filled rows='2' v-model='referral' label='How did you hear about Front Porch?' required :rules='textFieldRules'></v-textarea>
            <v-textarea filled v-model='comments' label='Tell us about your project'></v-textarea>
            <v-card-actions>
                <v-btn outlined rounded color='accent' type='submit' class='bold'>Send</v-btn>
                <v-spacer></v-spacer>
                <v-progress-circular indeterminate color='accent' v-if='loading'></v-progress-circular>
            </v-card-actions>
            </form>
        </v-card-text>
    </v-container>
</v-card>
<!-- https://codepen.io/elevaunt/pen/VvKdVa -->
</template>
<script>
import emailjs from 'emailjs-com';
export default({
    name: 'ContactForm',
    data() {
        return {
            types: ['Commercial', 'Residential'],
            subtypes: ['Update', 'Remodel', 'New Build'],
            loading: false,

            textFieldRules: [
                value => !!value || 'Required',
                value => (value || '').length <= 100 || 'Max 100 characters'
            ],
            phoneRules: [
                value => !!value || 'Required',
                value => /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(value) || 'Please enter a valid phone number'
            ],
            emailRules: [
                value => !!value || 'Required',
                value => /.+@.+\..+/.test(value) || 'Please enter a valid email address',
            ]
        }
    },
    computed: {
        firstName: {
            get() {
                return this.$store.state.firstName;
            },
            set(v) {
                this.$store.commit('updateFirstName', v)
            }
        },
        lastName: {
            get() {
                return this.$store.state.lastName;
            },
            set(v) {
                this.$store.commit('updateLastName', v)
            }
        },
        email: {
            get() {
                return this.$store.state.email;
            },
            set(v) {
                this.$store.commit('updateEmail', v)
            }
        },
        phone: {
            get() {
                return this.$store.state.phone;
            },
            set(v) {
                this.$store.commit('updatePhone', v)
            }
        },
        previousDesigner: {
            get() {
                return this.$store.state.previousDesigner;
            },
            set(v) {
                this.$store.commit('updatePreviousDesigner', v)
            }
        },
        projectType: {
            get() {
                return this.$store.state.projectType;
            },
            set(v) {
                this.$store.commit('updateProjectType', v)
            }
        },
        projectTimeline: {
            get() {
                return this.$store.state.projectTimeline;
            },
            set(v) {
                this.$store.commit('updateProjectTimeline', v)
            }
        },
        referral: {
            get() {
                return this.$store.state.referral;
            },
            set(v) {
                this.$store.commit('updateReferral', v)
            }
        },
        comments: {
            get() {
                return this.$store.state.comments;
            },
            set(v) {
                this.$store.commit('updateComments', v)
            }
        },
        sent: {
            get() {
                return this.$store.state.sent;
            },
            set(v) {
                this.$store.commit('updateSent', v)
            }
        },
        error: {
            get() {
                return this.$store.state.error;
            },
            set(v) {
                this.$store.commit('updateError', v)
            }
        }
    },
    methods: {
        close() {
            this.$emit('removeDialog');
        },
        sendEmail() {
            //emailjs.sendForm('ServiceId', 'TemplateId', 'e.target', 'UserId', {})
            this.loading = true;
            let previousDesignerStr = 'no';
            if (this.previousDesigner) {
                previousDesignerStr = 'yes';
            }
            let today = new Date();
            let date = `${today.getMonth()+1}/${today.getDate()}/${today.getFullYear()}`;
            let newSubmission = {
                submission_date: date,
                name: `${this.firstName} ${this.lastName}`,
                phone: this.phone,
                email: this.email,
                previous_designer: previousDesignerStr,
                project_type: this.projectType,
                project_timeline: this.projectTimeline,
                referral: this.referral,
                comments: this.comments
            }

            emailjs.send('service_777mbvg', 'fpdsnccf010324', newSubmission, 'YhSMEY6zUOmpGHqCP')
            .then((result) => {
                result;
                this.sent = true;
                this.loading = false;
                // this.$state.commit('resetForm');
            }, (error) => {
                error;
                this.error = true;
                this.loading = false;
                // this.$state.commit('resetForm');
            });
        },
    },
})
</script>
<style scoped>
.inline {
    display: inline;
}
svg {
  width: 100px;
  display: block;
  margin: 36px auto 36px;
}

.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
}
.circle {
    -webkit-animation: dash .9s ease-in-out;
    animation: dash .9s ease-in-out;
}
.line {
    stroke-dashoffset: 1000;
    -webkit-animation: dash .9s .35s ease-in-out forwards;
    animation: dash .9s .35s ease-in-out forwards;
}
.check {
    stroke-dashoffset: -100;
    -webkit-animation: dash-check .9s .35s ease-in-out forwards;
    animation: dash-check .9s .35s ease-in-out forwards;
  }


@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}

@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}
</style>