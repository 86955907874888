import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state() {
    return {
      authorized: false,
      user: null,
      
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      previousDesigner: false,
      projectType: '',
      projectTimeline: '',
      referral: '',
      comments: '',
      sent: false,
      error: false,
    }
  },
  getters: {
  },
  mutations: {
    resetForm (state) {
      setTimeout(function() {
        state.firstName = '';
        state.lastName = '';
        state.email = '';
        state.phone = '';
        state.previousDesigner = false;
        state.projectType = '';
        state.projectTimeline = '';
        state.referral = '';
        state.comments = '';
        state.sent = false;
        state.error = false;
      }, 8000)
    },
    updateAuthorized (state, v) {
      state.authorized = v;
    },
    updateUser (state, v) {
      state.user = v;
    },
    updateFirstName (state, v) {
      state.firstName = v;
    },
    updateLastName (state, v) {
      state.lastName = v;
    },
    updateEmail (state, v) {
      state.email = v;
    },
    updatePhone (state, v) {
      state.phone = v;
    },
    updatePreviousDesigner (state, v) {
      state.previousDesigner = v;
    },
    updateProjectType (state, v) {
      state.projectType = v;
    },
    updateProjectTimeline (state, v) {
      state.projectTimeline = v;
    },
    updateReferral (state, v) {
      state.referral = v;
    },
    updateComments (state, v) {
      state.comments = v;
    },
    updateSent (state, v) {
      state.sent = v;
    },
    updateError (state, v) {
      state.error = v;
    }
  },
  actions: {
  },
  modules: {
  }
})
