<template>
<div class='div-hero'>
    <v-container class='home-hero' fluid fill-height></v-container>
</div>
</template>

<script>
export default ({
    name: 'HomeHero',
})
</script>

<style scoped>
.home-hero {
    background-image: url('@/assets/heros/home-hero-sm.jpg');
    background-image: url('@/assets/heros/home-hero-sm.webp');
    background-size: cover;
    background-position: center;
}
</style>