<template>
<v-app>
  <NavBar :class="{ 'hide-navbar': $route.name === 'internal' || $route.name === 'internal login'}"></NavBar>
  <v-main>
    <router-view></router-view>
    <AppFooter :class="{ 'hide-navbar': $route.name === 'internal' || $route.name === 'internal login' }"></AppFooter>
  </v-main>
</v-app>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import AppFooter from '@/components/AppFooter.vue';

export default {
  name: 'App',
  navTheme: 'dark',
  components: { NavBar, AppFooter},
  mounted() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
};
</script>
<style>
:root {
  --primary: #000000;
  --secondary: #ffffff;
  --accent: #f09a9a;
  --grey: #9e9e9e;
}
@font-face {
  font-family: "Andasia";
  src: local("Andasia"),
   url(./assets/fonts/Andasia.ttf) format("truetype");
}
@font-face {
  font-family: "AndasiaSwash";
  src: local("Andasia Swash"),
    url(./assets/fonts/Andasia-Swash.ttf) format("truetype");
}
@import url("https://use.typekit.net/qdt8yzu.css");

#app {
  font-family: 'mrs-eaves', serif;
  word-wrap: break-word;
  overflow-x: hidden;
}
.v-list-item__title {
  font-family: mr-eaves-sans !important;
}
.hide-navbar {
  display: none;
}
.font-awesome {
  margin: 0px !important;
  padding: 8px;
}
.bold {
  font-weight: 700;
}
.lh-12 {
  line-height: 1.2;
}
.lh-1 {
  line-height: 1;
}
.lh-08 {
  line-height: 0.8;
}
.mrs-eaves-smallcaps {
  font-family: mrs-eaves-roman-small-caps, serif;
}
.mrs-eaves {
  font-family: mrs-eaves, serif;
}
.mr-eaves {
  font-family: mr-eaves-sans;
  font-weight: 300;
  font-style: normal;
}
.centered {
  text-align: center;
}
.andasia {
  font-family: Andasia !important;
  line-height: 1;
  word-break: break-word;
}
.andasia-swash {
  font-family: AndasiaSwash !important;
}
.ss05{
  -webkit-font-feature-settings: "ss05";
  -moz-font-feature-settings: "ss05";
  font-feature-settings: "ss05";    
}
.circle-img {
  border-radius: 50% !important;
}
.text-overflow {
  white-space: nowrap;
}
.no-bottom-margin {
  margin-bottom: 0px !important;
}
.no-padding {
  padding: 0px;
}
.neg-bottom-margin {
  margin-bottom: -36px;
}
.neg-top-margin {
  margin-top: -32px;
}
.div-hero {
  min-height: 640px;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  top: 0px;
  margin-top: -120px;
}
.nav-btn {
  font-size: 1.2em !important;
}
.thick-divider-v {
  border-width: 1px !important;
  border-color: #FFCDD2 !important;
}
.thick-divider-v-null {
  border-width: 1px !important;
}
.thick-divider-h {
  border-width: 1px !important;
  border-color: #FFCDD2 !important;
  margin-left: 15%;
  margin-right: 15%;
}
.hero-container {
  padding-top: 100px;
}
.light-grey-bkg {
  background: rgb(240,240,240);
}
.profile-divider {
  margin-top: 16px;
  margin-bottom: 8px;
}
.profile-name {
  font-size: 3em;
}
ul li {
  list-style-type: none;
}
/* --------------------------------------- */
.p-sm {
  font-size: 1.2em;
}
.p-md {
  font-size: 1.4em;
}
.p-md-lg {
  font-size: 1.4em;
}
.p-lg {
  font-size: 1.4em;
}
.md-size {
  font-size: 1.6em;
}
.md-lg-size {
  font-size: 3em;
}
.lg-size {
  font-size: 4em;
}
.xl-size {
  font-size: 1.8em;
}
.sm-header {
  font-size: 1.4em;
}
.md-header {
  font-size: 2em;
}
.big-header {
  font-size: 2em;
}
.heading-size {
  font-size: 1.6em;
}
.full-img {
  height: 50vh;
  background-size: cover;
}
.section-start {
  padding-top: 36px;
  padding-bottom: 18px;
}
.section-mid {
  padding-top: 18px;
  padding-bottom: 18px;
}
.section-end {
  padding-top: 18px;
  padding-bottom: 36px;
}
.section-full {
  padding-top: 36px;
  padding-bottom: 36px;
}
/* ----- sm-size screen ----- */
@media only screen and (min-width: 600px) {
  .p-md-lg {
    font-size: 1.8em;
  }
  .md-header {
    font-size: 4em;
  }
  .xl-size {
    font-size: 3em;
  }
}
/* ----- md size screen ----- */
@media only screen and (min-width: 960px) {
  .p-sm {
    font-size: 1.2em;
  }
  .p-md {
    font-size: 1.6em;
  }
  .p-md-lg {
    font-size: 1.8em;
  }
  .p-lg {
    font-size: 2em;
  }
  .md-size {
    font-size: 2.2em;
  }
  .md-lg-size {
    font-size: 3em;
  }
  .lg-size {
    font-size: 4em;
  }
  .xl-size {
    font-size: 5em;
  }
  .sm-header {
    font-size: 1.8em;
  }
  .md-header {
    font-size: 6.5em;
  }
  .big-header {
    font-size: 7em;
  }
  .heading-size {
    font-size: 3em;
  }
  .full-img {
    height: 75vh;
    background-size: cover;
  }
  .section-start {
    padding-top: 108px;
    padding-bottom: 72px;
  }
  .section-mid {
    padding-top: 72px;
    padding-bottom: 72px;
  }
  .section-end {
    padding-top: 72px;
    padding-bottom: 108px;
  }
  .section-full {
    padding-top: 108px;
    padding-bottom: 108px;
  }
  .section-sm {
    padding-top: 36px;
    padding-bottom: 36px;
  }
  .section-thin {
    padding-top: 18px;
    padding-bottom: 18px;
  }
}

/* ----- lg size screen ----- */
@media only screen and (min-width: 1248px) {
  .p-sm {
    font-size: 1.2em;
  }
  .p-md {
    font-size: 1.6em;
  }
  .p-md-lg {
    font-size: 1.8em;
  }
  .p-lg {
    font-size: 2em;
  }
  .p-xl {
    font-size: 2.5em;
  }
  .md-size {
    font-size: 2.2em;
  }
  .lg-size {
    font-size: 4em;
  }
  .xl-size {
    font-size: 5em;
  }
  .md-header {
    font-size: 6.5em;
  }
  .big-header {
    font-size: 7em;
  }
  .heading-size {
    font-size: 3em;
  }
  .section-start {
    padding-top: 72px;
    padding-bottom: 36px;
  }
  .section-mid {
    padding-top: 36px;
    padding-bottom: 36px;
  }
  .section-end {
    padding-top: 36px;
    padding-bottom: 72px;
  }
  .section-full {
    padding-top: 72px;
    padding-bottom: 72px;
  }
}
</style>