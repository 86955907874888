<template>
<span>
    <v-navigation-drawer right app v-model='drawer' color='#ffffffd9' class='nav' disable-resize-watcher>
        <v-btn plain text x-large :ripple=false @click='drawer = !drawer'><font-awesome-icon icon='fa-solid fa-xmark fa-fw' size='2x'/></v-btn>
        <v-list>
            <v-list-item to='/' class='hidden-sm-and-up'>
                <v-list-item-content class='justify-center'>HOME</v-list-item-content>
            </v-list-item>
            <template v-for='(link, index) in pageDrawer'>
                <v-list-item :key='index' :to='link.url'>
                    <v-list-item-content class='justify-center'>{{link.name}}</v-list-item-content>
                </v-list-item>
            </template>
        </v-list>
        <v-dialog v-model='dialog0' hide-overlay>
            <template v-slot:activator="{ on, attrs }">
                <div class='centered'>
                    <v-btn v-bind="attrs" v-on="on" elevation='0' rounded color='accent' class='font-weight-bold'>Let's get started!</v-btn>
                </div>
            </template>
            <ContactForm @removeDialog='removeDialog'></ContactForm>
        </v-dialog>
    </v-navigation-drawer>
    <v-app-bar hide-on-scroll app height='120' color='#ffffffd9' scroll-threshold='64' flat class='nav'>
        <v-card flat tile width='100%' class='hidden-sm-and-down' color='transparent'>
            <!-- Desktop Nav -->
            <v-row justify='center' align='center'>
                <v-col cols='4.5' class='d-flex justify-space-around align-center'>
                    <v-btn to='/' plain tile text :ripple='false' class='main-nav hidden-sm-and-down nav-btn' data-aos='fade' data-aos-delay='200'><font-awesome-icon icon='fa-solid fa-house'/></v-btn>
                    <v-btn to='/ourstory' plain tile text :ripple='false' class='main-nav hidden-sm-and-down nav-btn bold' x-large data-aos='fade' data-aos-delay='400'>OUR STORY</v-btn>
                    <v-btn to='/studio' plain tile text :ripple='false' class='main-nav hidden-sm-and-down nav-btn bold' x-large data-aos='fade' data-aos-delay='600'>STUDIO</v-btn>
                </v-col>
                <v-col cols='3'>
                    <v-img src='@/assets/logos/FPDS_text_T.png' @click='goHome' contain width='90%' class='mx-auto nav-logo' data-aos='fade'></v-img>
                </v-col>
                <v-col cols='4.5' class='d-flex justify-space-around align-center'>
                    <v-btn to='/portfolio' plain tile text :ripple='false' class='main-nav hidden-sm-and-down nav-btn bold' x-large data-aos='fade' data-aos-delay='800'>PORTFOLIO</v-btn>
                    <v-dialog v-model='dialog1' max-width='700px'>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn rounded v-bind="attrs" v-on="on" elevation='0' color='accent' class='main-nav hidden-sm-and-down bold nav-form-btn' data-aos='fade' data-aos-delay='1000'>Let's get started!</v-btn>
                        </template>
                        <ContactForm @removeDialog='removeDialog' class='dialog-once'></ContactForm>
                    </v-dialog>
                </v-col>
            </v-row>
        </v-card>

        <!-- Tablet and Mobile Nav -->
        <v-card flat tile class='hidden-md-and-up' color='transparent'>
            <v-row justify='center' align='center'>
                <v-col cols='3' sm='3' class='hidden-xs-only centered'>
                    <v-btn to='/' plain text :ripple=false class='hidden-xs-only' data-aos='fade'><font-awesome-icon icon='fa-solid fa-house' size='2x'/></v-btn>
                </v-col>
                <v-col cols='9' sm='6'>
                    <v-img src='@/assets/logos/FPDS_text_T_medium.png' @click='goHome' contain width='90%' eager class='mx-auto nav-logo' data-aos='fade'></v-img>
                </v-col>
                <v-col cols='3' class='centered'>
                    <v-btn plain text :ripple=false @click='drawer = !drawer' class='mx-auto' data-aos='fade'><font-awesome-icon icon='fa-solid fa-bars' size='2x'/></v-btn>
                </v-col>
            </v-row>
        </v-card>
    </v-app-bar>
</span>
</template>
<script>
import ContactForm from '@/components/ContactForm.vue';

export default ({
    name: 'NavBar',
    components: { ContactForm },
    data() {
        return {
            drawer: false,
            dialog0: false,
            dialog1: false,
            dialog2: false,
            pageDrawer: [
                {name: 'OUR STORY', url: '/ourstory'},
                {name: 'STUDIO', url: '/studio'},
                {name: 'PORTFOLIO', url: '/portfolio'},
            ],
            navBkg: '#ffffffcc'
        }
    },
    methods: {
        removeDialog() {
            this.dialog0 = false;
            this.dialog1 = false;
            this.dialog2 = false;
        },
        goHome() {
            this.$router.push({
                name: 'home'
            })
        }
    }
})
</script>
<style scoped>
.no-top-margin {
    margin-top: 0px;
}

.nav-logo:hover {
    cursor: pointer;
}
.nav {
    backdrop-filter: blur(6px);
}
@media only screen and (max-width: 1080px) {
    .nav-form-btn {
        display: none;
    }
}
@media only screen and (min-width: 2048px) {
    .nav-logo {
        height: 100px;
    }
}

</style>
