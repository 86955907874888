<template>
<span>
  <HomeHero data-aos='fade'></HomeHero>
    <v-card flat tile color='transparent' class='section-start hidden-sm-and-down' data-aos='fade' data-aos-duration='2000'>
      <v-card-text>
        <br>
        <h1 class='centered andasia red--text text--lighten-3 font-weight-regular md-header'>Front Porch welcomes you hom<strong class='font-weight-regular andasia-swash'>e</strong></h1>
      </v-card-text>
    </v-card>
    <v-card flat tile color='transparent' class='hidden-md-and-up' data-aos='fade'>
      <v-card-text>
        <br><br>
        <h1 class='centered andasia red--text text--lighten-3 font-weight-regular md-header'>Front Porch welcomes you hom<strong class='font-weight-regular andasia-swash'>e</strong></h1>
      </v-card-text>
    </v-card>
    <v-card class='section-end' flat tile color='transparent' data-aos='fade'>
      <v-container>
        <v-row align='center' justify='center'>
          <v-col cols='11' md='6'>
            <v-img :aspect-ratio="1/1" src='@/assets/img/Front_Porch6.jpg' width='80%' class='mx-auto' eager></v-img>
          </v-col>
          <v-divider vertical class='thick-divider-v hidden-sm-and-down'></v-divider>
          <br class='hidden-md-and-up'>
          <v-col cols='11' md='6'>
            <v-card-text>
              <!-- text edits -->
              <!-- <p class='mr-eaves p-md-lg lh-12 grey--text text--darken-3'>Front Porch Design Studio is a full service interior design firm serving the Fox Valley area of Northeast Wisconsin.</p> -->
              <!-- <p class='mr-eaves p-md-lg lh-12 grey--text text--darken-3'>Feeling like a remodel is long overdue?<br>Thinking of building but have no idea where to start?</p> -->
              <p class='mr-eaves p-md-lg lh-12 grey--text text--darken-3'>Front Porch Design Studio is your trusted partner for a home refresh, renovation, or new build project.<p>
              <p class='mr-eaves p-md-lg lh-12 grey--text text--darken-3'>Your existing home doesn&#8217;t need to frustrate you anymore. The process of building shouldn&#8217;t be confusing. This is where Front Porch steps in and replaces the angst with ease, simplicity, enjoyment, and fun!<p>
              <!-- <p class='mr-eaves p-md-lg lh-12 grey--text text--darken-3'>Front Porch gets it. We’ve been there and our team is here to assist you. We have a three-step process that allows us to grab your hand and walk you through each stage with ease and a touch of fun.</p> -->
              <p class='mr-eaves p-md-lg lh-12 grey--text text--darken-3'>Our collaborative approach combines our expertise with your vision, resulting in a bespoke solution that exceeds expectations.<p>
              <p class='mr-eaves p-md-lg lh-12 grey--text text--darken-3'>With our skilled leadership, you&#8217;ll experience a personalized and attentive service that seamlessly aligns with your individual preferences, resulting in a project that reflects your unique style.</p>
              <p class='mr-eaves p-md-lg lh-12 grey--text text--darken-3'>We take out the frustration and put back in the fun!<p>

              <!-- <p class='mr-eaves p-md-lg lh-12 grey--text text--darken-3'>With Front Porch Design Studio, your project transcends expectations, weaving together innovation and imagination to create an extraordinary result. Trust in our expertise as we journey together to craft a space that not only reflects your unique style, but also captures the essence of who you are. Experience the joy of a truly elevated project experience, where design becomes an immersive and transformative journey.</p> -->
              <!-- keep italics same -->
              <!-- <p class='mr-eaves p-md-lg lh-12 grey--text text--darken-3'>We understand that your project deserves the utmost attention and care. That's why we take a deeply personalized approach to every aspect of our service. From the initial concept to the final touches, we meticulously tailor our approach to ensure your project receives the dedication it deserves.</p> -->
              <p class='mr-eaves p-md-lg lh-12 font-weight-regular font-italic grey--text text--darken-3'>We would love to meet you, schedule a consultation today!</p>
              <v-dialog v-model='dialog3' max-width='700px' tile>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" elevation='0' rounded x-large color='accent' class='bold'>Let's Get Started!</v-btn>
                </template>
                <ContactForm @removeDialog='removeDialog'></ContactForm>
              </v-dialog>                 
            </v-card-text>
          </v-col>
        </v-row>
    </v-container>
    </v-card>
    <v-card class='section-start' flat color='#f5f5f5'>
      <v-container fluid>
        <!-- desktop animations -->
      <v-row justify='center' class='hidden-sm-and-down'>
        <v-col cols='11' class='d-flex justify-space-between justify-xl-space-around'>
          <v-card flat tile width='400px' color='transparent' data-aos='fade-up'>
                <v-row justify='center' align='center' class='neg-bottom-margin'>
                    <v-col cols='10' class='centered'>
                        <img src='@/assets/img/listen-sm.jpg' alt='We Listen' width='100%' class='circle-img'>
                    </v-col>
                </v-row>
                <v-card-title class='justify-center sm-header accent--text'><h1 class='andasia font-weight-regular centered'>We Discove<strong class='andasia-swash font-weight-regular'>r</strong></h1></v-card-title>
                <v-card-text>
                    <!-- <p class='mr-eaves p-md lh-12 grey--text text--darken-3 no-bottom-margin'>Your home should work for you and your family, so we begin by listening to your unique needs, desires, and dreams for your home.<br><br><strong class='font-italic font-weight-regular'>Home should be a beautiful reflection of you.</strong></p> -->
                    <p class='mr-eaves p-md lh-12 grey--text text--darken-3 no-bottom-margin'>Through in-depth consultations and thoughtful discussion, we&#8217;ll discover your design preferences, functional requirements, and desired aesthetics. We&#8217;ll explore your lifestyle, habits and how you envision using each space in your home.  This collaboration allows us to craft a design that meets your needs and reflects your personality, creating an environment where you feel truly at home.<br><br><strong class='font-italic font-weight-regular'>Home should be a beautiful reflection of you.</strong></p>
                </v-card-text>
            </v-card>
            <v-card flat tile width='400px' color='transparent' data-aos='fade-up' data-aos-delay='500'>
                <v-row justify='center' align='center' class='neg-bottom-margin'>
                    <v-col cols='10' class='centered'>
                        <img src='@/assets/img/develop.jpg' alt='We Develop' width='100%' class='circle-img'>
                    </v-col>
                  </v-row>
                  <v-card-title class='justify-center sm-header accent--text'><h1 class='andasia font-weight-regular centered'>We Develo<strong class='andasia-swash font-weight-regular text-overflow'>p</strong></h1></v-card-title>
                  <v-card-text>
                      <!-- <p class='mr-eaves p-md lh-12 grey--text text--darken-3 no-bottom-margin'>Once we understand your needs, the Front Porch team develops a comprehensive vision to meet those needs through drawings, mood boards, and schedules.<br><br><strong class='font-italic font-weight-regular'>Home should function effortlessly.</strong></p> -->
                      <p class='mr-eaves p-md lh-12 grey--text text--darken-3 no-bottom-margin'>Once we have a deep comprehension of your life and style, we seamlessly integrate it with our design expertise, resulting in a comprehensive plan that navigates your project from inception to completion. During this collaborative process, you&#8217;ll witness the full extent of our design muscles as we craft a roadmap tailored specifically to your needs, ensuring a successful journey from start to finish.<br><br><strong class='font-italic font-weight-regular'>Home should function effortlessly.</strong></p>
                  </v-card-text>
              </v-card>
              <v-card flat tile width='400px' color='transparent' data-aos='fade-up' data-aos-delay='1000'>
                <v-row justify='center' align='center' class='neg-bottom-margin'>
                    <v-col cols='10' class='centered'>
                        <img src='@/assets/img/img_0167_sm.jpg' alt='We Oversee' width='100%' class='circle-img'>
                    </v-col>
                </v-row>
                <v-card-title class='justify-center sm-header accent--text'><h1 class='andasia font-weight-regular centered'>We Delive<strong class='andasia-swash font-weight-regular'>r</strong></h1></v-card-title>
                <v-card-text>
                    <!-- <p class='mr-eaves p-md lh-12 grey--text text--darken-3 no-bottom-margin'>The process of building, renovating, or redesigning a home can be overwhelming. We oversee every detail so that the process is smooth - and even fun!<br><br><strong class='font-italic font-weight-regular'>Home becomes a place to slow down, connect, and celebrate life's moments.</strong></p> -->
                    <p class='mr-eaves p-md lh-12 grey--text text--darken-3 no-bottom-margin'>After months of hard work and collaboration, we witness the magical transformation of your home into a space where time slows down, allowing you to savor every moment. It becomes a haven where you can connect with loved ones, creating meaningful memories that will last a lifetime. Together, we celebrate the culmination of your project, knowing that your home now serves as a backdrop for joy, love, and cherished moments.<br><br><strong class='font-italic font-weight-regular'>Home should be a place to slow down, connect, and enjoy life together.</strong></p>
                </v-card-text>
            </v-card>
        </v-col>
      </v-row>
      <!-- mobile animations -->
      <v-row justify='center' class='hidden-md-and-up'>
        <v-col cols='11' sm='8' md='4' class='mobile-no-padding'>
            <v-card flat tile color='transparent' data-aos='fade-up'>
                <v-row justify='center' align='center' class='neg-bottom-margin'>
                    <v-col class='centered'>
                        <img src='@/assets/img/listen-sm.jpg' alt='We Develop' height='300' class='circle-img'>
                    </v-col>
                </v-row>
                <v-card-title class='justify-center sm-header accent--text'><h1 class='andasia font-weight-regular centered'>We Discove<strong class='andasia-swash font-weight-regular'>r</strong></h1></v-card-title>
                <v-card-text>
                    <p class='mr-eaves p-md lh-12 grey--text text--darken-3 no-bottom-margin'>Through in-depth consultations and thoughtful discussion, we&#8217;ll discover your design preferences, functional requirements, and desired aesthetics. We&#8217;ll explore your lifestyle, habits and how you envision using each space in your home.  This collaboration allows us to craft a design that meets your needs and reflects your personality, creating an environment where you feel truly at home.<br><br><strong class='font-italic font-weight-regular'>Home should be a beautiful reflection of you.</strong></p>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols='11' sm='8' md='4' class='mobile-no-padding'>
            <v-card flat tile color='transparent' data-aos='fade-up'>
                <v-row justify='center' align='center' class='neg-bottom-margin'>
                    <v-col class='centered'>
                        <img src='@/assets/img/develop-sm.jpg' alt='We Listen' height='300' class='circle-img'>
                    </v-col>
                  </v-row>
                  <v-card-title class='justify-center sm-header accent--text'><h1 class='andasia font-weight-regular centered'>We Develo<strong class='andasia-swash font-weight-regular'>p</strong></h1></v-card-title>
                  <v-card-text>
                      <p class='mr-eaves p-md lh-12 grey--text text--darken-3 no-bottom-margin'>Once we have a deep comprehension of your life and style, we seamlessly integrate it with our design expertise, resulting in a comprehensive plan that navigates your project from inception to completion. During this collaborative process, you&#8217;ll witness the full extent of our design muscles as we craft a roadmap tailored specifically to your needs, ensuring a successful journey from start to finish.<br><br><strong class='font-italic font-weight-regular'>Home should function effortlessly.</strong></p>
                  </v-card-text>
              </v-card>
          </v-col>
          <v-col cols='11' sm='8' md='4' class='mobile-no-padding'>
            <v-card flat tile color='transparent' data-aos='fade-up'>
                <v-row justify='center' align='center' class='neg-bottom-margin'>
                    <v-col class='centered'>
                        <img src='@/assets/img/img_0167_sm.jpg' alt='We Oversee' height='300' class='circle-img'>
                    </v-col>
                </v-row>
                <v-card-title class='justify-center sm-header accent--text'><h1 class='andasia font-weight-regular centered'>We Delive<strong class='andasia-swash font-weight-regular'>r</strong></h1></v-card-title>
                <v-card-text>
                    <p class='mr-eaves p-md lh-12 grey--text text--darken-3 no-bottom-margin'>After months of hard work and collaboration, we witness the magical transformation of your home into a space where time slows down, allowing you to savor every moment. It becomes a haven where you can connect with loved ones, creating meaningful memories that will last a lifetime. Together, we celebrate the culmination of your project, knowing that your home now serves as a backdrop for joy, love, and cherished moments.<br><br><strong class='font-italic font-weight-regular'>Home should be a place to slow down, connect, and enjoy life together.</strong></p>
                </v-card-text>
            </v-card>
          </v-col>
      </v-row>
      </v-container>
      </v-card>
  <GratitudeQuote quote='Liz has beautifully guided us through 2 new construction homes in the last 3 years in multiple states. Her attention to detail and design has made each house a home that we love to live in and welcome our family and friends to. Every area was thoroughly thought out...lighting, flooring, cabinetry, hardware, tile, wallpaper, window coverings, furniture placement and many more. We are blessed to have her as our designer, but especially our friend!' from='Steve &amp; Jill H.' img='IMG_9297.jpg'></GratitudeQuote>
</span>
</template>

<script>
import HomeHero from '@/components/HomeHero.vue';
import GratitudeQuote from '@/components/GratitudeQuote.vue';
import ContactForm from '@/components/ContactForm.vue';

export default {
  name: 'HomeView',
  components: { HomeHero, GratitudeQuote, ContactForm },
  data() {
    return {
      dialog3: false,
    }
  },
  methods: {
    removeDialog() {
            this.dialog3 = false;
        }
  },
  created() {
    document.title = "Front Porch Design Studio";
  }
}
</script>
<style scoped>
.homeview-img-1 {
  background-image: url('@/assets/img/fpds-1-59.jpg');
  background-position-x: center;
  background-position-y: top;
}
.up-on-hover:hover {
  padding-top: 0px;
}
</style>