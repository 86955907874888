<template>
    <v-card flat fill-width>
        <v-card flat tile fill-width data-aos='fade'>
            <v-container class='centered footer-img' fluid>
                <v-row align='end' justify='center' style='height: 75vh;'>
                    <v-col>
                    <v-card-text class='footer-overlay'>
                        <h1 class='big-header font-weight-regular lh-1 white--text' style='word-break: break-word'>We&#8217;re so happy you&#8217;re here!</h1>
                        <br>
                        <p class='p-lg mr-eaves white--text'>Reach out to us via our form below to schedule a no-strings-attached video chat.</p>
                        
                        <v-dialog v-model='dialog0' max-width='700px' tile>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" elevation='0' rounded x-large color='accent' class='bold'>Let's Get Started!</v-btn>
                            </template>
                            <ContactForm @removeDialog='removeDialog' class='dialog-once'></ContactForm>
                        </v-dialog>
                    </v-card-text>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
        <!-- Desktop -->
        <v-row justify='center' class='hidden-sm-and-down'>
            <v-col cols='11' md='11' class='d-flex justify-space-between justify-xl-space-around'>
                <v-card class='centered my-auto' flat tile height='140' width='306' data-aos='fade' data-aos-delay='600' data-aos-offset='0'>
                    <v-card-text>
                        <h1 class='footer-heading grey--text'>QUESTIONS?</h1>
                        <p class='subheading-1 accent--text font-italic sm-bot-marg'>contact us at</p>
                        <v-btn href='mailto:info@frontporchds.com' target='blank' plain x-large class='no-uppercase text-lowercase subheading-size' :ripple='false'>info@frontporchds.com</v-btn>
                    </v-card-text>
                </v-card>
                <v-card class='d-flex flex-column align-center justify-center' flat data-aos='fade' data-aos-delay='300' data-aos-offset='0'>
                    <br>
                    <v-img src='@/assets/logos/FPDS_text_W.png' width='400' @click='goHome' class='footer-logo' eager></v-img>
                    <br>
                    <v-card-text class='hidden-md-and-down' data-aos='fade' data-aos-duration='3000' data-aos-delay='0' data-aos-offset='0'>
                        <p class='andasia p-xl accent--text centered'>Front Porch welcomes you hom<strong class='font-weight-regular andasia-swash'>e</strong></p>
                    </v-card-text>
                </v-card>
                <v-card class='centered my-auto' flat tile height='140' width='306' data-aos='fade' data-aos-delay='600' data-aos-offset='0'>
                    <v-card-text>
                        <h1 class='footer-heading grey--text'>SOCIAL</h1>
                        <br>
                        <v-btn href='https://www.facebook.com/frontporchds/' target='blank' plain class='accent--text' :ripple='false'><font-awesome-icon icon='fa-brands fa-facebook-f' size='3x'/></v-btn>
                        <v-btn href='https://www.instagram.com/frontporchds/?hl=en' target='blank' plain class='accent--text' :ripple='false'><font-awesome-icon icon='fa-brands fa-instagram' size='3x'/></v-btn>
                        <v-btn href='mailto:info@frontporchds.com' target='blank' plain class='accent--text' :ripple='false'><font-awesome-icon icon='fa-solid fa-envelope' size='3x'/></v-btn>
                        <br><br>
                        <v-row class='mx-auto justify-space-around'>
                            <v-col cols='8'>
                                <v-btn href="https://nkba.org/" target='blank' plain :ripple='false' class='nkba'>
                                    <v-img src="@/assets/logos/NKBAlogo_Member-2023_Black-720x184-f06e9cf0-5ed5-445d-ba41-7349b33d556c.png" width="148"></v-img>
                                </v-btn>
                            </v-col>
                            <v-col cols='4'>
                                <v-btn href="https://www.nari.org/" target='blank' plain :ripple='false' class='nkba'>
                                    <v-img src="@/assets/logos/NARI_Member_Logo_2016_Full_RGB.png" width='56'></v-img>
                                </v-btn> 
                            </v-col>
                        </v-row>
                        
                        <!-- <v-btn plain class='accent--text' :ripple='false'><font-awesome-icon icon='fa-brands fa-pinterest-p' size='3x'/></v-btn> -->
                    </v-card-text>
                </v-card>
                <!-- rounded pink social btns -->
                <!-- <v-card class='centered my-auto' flat tile height='140' width='306' data-aos='fade' data-aos-delay='600' data-aos-offset='0'>
                    <v-card-text>
                        <h1 class='footer-heading grey--text'>SOCIAL</h1>
                        <br>
                        <v-row>
                            <v-col cols='12' class='d-flex justify-space-between'>
                                <v-btn href='https://www.facebook.com/frontporchds/' target='blank' fab elevation='0' color='accent' class='white--text' :ripple='false'><font-awesome-icon icon='fa-brands fa-facebook-f' size='3x'/></v-btn>
                                <v-btn href='https://www.instagram.com/frontporchds/?hl=en' target='blank' fab elevation='0' color='accent' class='white--text' :ripple='false'><font-awesome-icon icon='fa-brands fa-instagram' size='3x'/></v-btn>
                                <v-btn href='mailto:info@frontporchds.com' target='blank' fab elevation='0' color='accent' class='white--text' :ripple='false'><font-awesome-icon icon='fa-solid fa-envelope' size='3x'/></v-btn>
                                <v-btn fab elevation='0' color='accent' class='white--text' :ripple='false'><font-awesome-icon icon='fa-brands fa-pinterest-p' size='3x'/></v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card> -->
            </v-col>
        </v-row>
        <!-- Tablet  -->
        <v-row justify='center' align='center' class='hidden-xs-only hidden-md-and-up'>
            <v-col cols='5' class='align-content'>
                <v-card class='centered' flat tile data-aos='fade' data-aos-delay='600' data-aos-offset='0'>
                    <v-card-text>
                        <br><br>
                        <h1 class='footer-heading grey--text'>QUESTIONS?</h1>
                        <p class='subheading-1 accent--text font-italic sm-bot-marg'>contact us at</p>
                        <v-btn href='mailto:info@frontporchds.com' target='blank' plain x-large class='no-uppercase text-lowercase subheading-size' :ripple='false'>info@frontporchds.com</v-btn>
                        <br><br>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols='5'>
                <v-card class='centered' flat tile data-aos='fade' data-aos-delay='600' data-aos-offset='0'>
                    <v-card-text>
                        <h1 class='footer-heading grey--text'>SOCIAL</h1>
                        <br>
                        <v-btn href='https://www.facebook.com/frontporchds/' target='blank' plain class='accent--text' :ripple='false'><font-awesome-icon icon='fa-brands fa-facebook-f' size='3x'/></v-btn>
                        <v-btn href='https://www.instagram.com/frontporchds/?hl=en' target='blank' plain class='accent--text' :ripple='false'><font-awesome-icon icon='fa-brands fa-instagram' size='3x'/></v-btn>
                        <v-btn href='mailto:info@frontporchds.com' target='blank' plain class='accent--text' :ripple='false'><font-awesome-icon icon='fa-solid fa-envelope' size='3x'/></v-btn>
                        <!-- <v-btn plain class='accent--text' :ripple='false'><font-awesome-icon icon='fa-brands fa-pinterest-p' size='3x'/></v-btn> -->
                        
                        
                        <br class='hidden-sm-and-up'><br class='hidden-sm-and-up'><br class='hidden-sm-and-up'>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols='12' class='centered'>
                <v-btn href="https://nkba.org/" target='blank' plain :ripple='false' class='nkba'>
                    <v-img src="@/assets/logos/NKBAlogo_Member-2023_Black-720x184-f06e9cf0-5ed5-445d-ba41-7349b33d556c.png" width="120"></v-img>
                </v-btn>
                <v-btn href="https://www.nari.org/" target='blank' plain :ripple='false' class='nkba'>
                    <v-img src="@/assets/logos/NARI_Member_Logo_2016_Full_RGB.png" width='56'></v-img>
                </v-btn> 
                <br><br>
            </v-col>
        </v-row>
        
        <!-- Mobile -->
        <v-row justify='center' class='hidden-sm-and-up'>
            <v-col>
                <v-card class='centered' flat tile data-aos='fade' data-aos-delay='600' data-aos-offset='0'>
                    <v-card-text>
                        <h1 class='footer-heading grey--text'>QUESTIONS?</h1>
                        <p class='subheading-1 accent--text font-italic sm-bot-marg'>contact us at</p>
                        <v-btn href='mailto:info@frontporchds.com' target='blank' plain x-large class='no-uppercase text-lowercase subheading-size' :ripple='false'>info@frontporchds.com</v-btn>
                        <br><br>
                        <h1 class='footer-heading grey--text'>SOCIAL</h1>
                        <br>
                        <v-btn href='https://www.facebook.com/frontporchds/' target='blank' plain class='accent--text' :ripple='false'><font-awesome-icon icon='fa-brands fa-facebook-f' size='3x'/></v-btn>
                        <v-btn href='https://www.instagram.com/frontporchds/?hl=en' target='blank' plain class='accent--text' :ripple='false'><font-awesome-icon icon='fa-brands fa-instagram' size='3x'/></v-btn>
                        <v-btn href='mailto:info@frontporchds.com' target='blank' plain class='accent--text' :ripple='false'><font-awesome-icon icon='fa-solid fa-envelope' size='3x'/></v-btn>
                        <br><br>
                        <v-btn href="https://nkba.org/" target='blank' plain :ripple='false' class='nkba'>
                            <v-img src="@/assets/logos/NKBAlogo_Member-2023_Black-720x184-f06e9cf0-5ed5-445d-ba41-7349b33d556c.png" width="120"></v-img>
                        </v-btn>
                        <v-btn href="https://www.nari.org/" target='blank' plain :ripple='false' class='nkba'>
                            <v-img src="@/assets/logos/NARI_Member_Logo_2016_Full_RGB.png" width='56'></v-img>
                        </v-btn> 
                        <!-- <v-btn plain class='accent--text' :ripple='false'><font-awesome-icon icon='fa-brands fa-pinterest-p' size='3x'/></v-btn> -->
                        <br><br>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-card flat tile color='accent'>
            <v-card-text class='centered mrs-eaves-smallcaps secondary--text sm-padding hidden-xs-only'><router-link to='' class='link'>&copy; 2024 Front Porch Design Studio</router-link> | site design by Ben Schaser</v-card-text>
            <v-card-text class='centered mrs-eaves-smallcaps secondary--text sm-padding hidden-sm-and-up'><router-link to='' class='link'>&copy; 2024 Front Porch Design Studio</router-link><br>site design by Ben Schaser</v-card-text>
        </v-card>
    </v-card>
</template>
<script>
import ContactForm from '@/components/ContactForm.vue';
export default({
    name: 'AppFooter',
    components: { ContactForm },
    data() {
        return {
            dialog0: false,
        }
    },
    methods: {
        removeDialog() {
            this.dialog0 = false;
        },
        goHome() {
            this.$router.push({
                name: 'home'
            })
        }
    }
})
</script>
<style scoped>
.nkba {
    opacity: 0.5;
}
.nkba:hover {
    opacity: 1.0;
}
.subheading-size {
    font-size: 1.4em;
}
.subheading-1 {
    font-size: 2.2em;
}
.subheading-2 {
    font-size: 2.6em;
}
.top-bottom-margin {
    margin-top: 24px;
    margin-bottom: 48px;
}
.sm-padding {
    padding: 8px;
}
.link {
    color: white;
    text-decoration: none;
}
.footer-heading {
    font-size: 2.2em;
    margin: 8px;
}
.sm-bot-marg {
    margin-bottom: 8px;
}
.footer-img {
    height: 75vh;
    /* height: calc(var(--vh, 1vh) * 75); */
    transition: height 0.2s linear;
    background-image: url('@/assets/img/footer-sm.webp'), linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25));
    background-blend-mode: overlay;
    background-size: cover;
    background-position: center 50%;
}
.footer-overlay {
    transition: transform 0.2s linear;
}
.footer-logo:hover {
    cursor: pointer;
}
</style>