<template>
<v-card flat tile class='section-full' data-aos='fade'>
<v-container>
<v-row align='center' justify='center'>
    <v-col cols='11' sm='6' class='hidden-sm-and-up'>
        <v-img :src="require(`@/assets/img/${img}`)" :aspect-ratio="1/1" width='80%' class='mx-auto' eager></v-img>
    </v-col>
    <v-col cols='11' sm='6'>
        <v-card flat tile class='text-right hidden-sm-and-down'>
            <v-card-text>
                <p class='mr-eaves p-md-lg lh-12 grey--text text--darken-3 quote'>&#8220;{{quote}}&#8221;</p>
                <p class='p-md-lg grey--text text--darken-3 from'>- {{from}}</p>
            </v-card-text>
        </v-card>
        <v-card flat tile class='text-left hidden-md-and-up'>
            <v-card-text>
                <p class='mr-eaves p-md-lg lh-12 grey--text text-left text-sm-right text--darken-3 quote'>&#8220;{{quote}}&#8221;</p>
                <p class='p-md-lg grey--text text--darken-3 centered'>- {{from}}</p>
            </v-card-text>
        </v-card>
    </v-col>
    <v-divider class='thick-divider-v hidden-xs-only' vertical></v-divider>
    <v-col cols='11' sm='6' class='hidden-xs-only'>
        <v-img :src="require(`@/assets/img/${img}`)" :aspect-ratio="1/1" width='80%' class='mx-auto' eager></v-img>
    </v-col>
</v-row>
</v-container>
</v-card>
</template>
<script>
export default({
    name: 'GratitudeQuote',
    props: ['quote', 'from', 'img'],
})
</script>
<style scoped>
.from {
    margin-right: 10%;
}

.quote {
    white-space: pre-wrap;
}
</style>
