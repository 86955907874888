import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/index.html',
    name: 'fallback',
    component: HomeView
  },
  {
    path: '/ourstory',
    name: 'ourstory',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "OurStory" */ /* webpackPrefetch: true */ '../views/OurStoryView.vue')
  },
  {
    path: '/blog',
    name: 'blog',
    component: () => import(/* webpackChunkName: "OurStory" */ /* webpackPrefetch: true */ '../views/BlogView.vue')
  },
  {
    path: '/studio',
    name: 'studio',
    component: () => import(/* webpackChunkName: "Studio" */ /* webpackPrefetch: true */ '../views/StudioView.vue')
  },
  {
    path: '/portfolio',
    name: 'portfolio',
    component: () => import(/* webpackChunkName: "Portfolio" */ /* webpackPrefetch: true */ '../views/PortfolioView.vue')
  },
  {
    path: '/copyright',
    name: 'copyright',
    component: () => import(/* webpackChunkName: "Copyright" */ /* webpackPrefetch: true */ '../views/CopyrightView.vue')
  },
  {
    path: '/portfolio/snowdrift_dr',
    name: 'snowdrift_dr',
    props: {
      projectId: 1,
      projectName: "Snowdrift Dr.",
      projectLocation: "Appleton, WI",
      showLoc: false
    },
    component: () => import(/* webpackChunkName: "SnowdriftDr" */ /* webpackPrefetch: true */ '../views/ProjectView.vue')
  },
  {
    path: '/portfolio/crystal_mountain_dr',
    name: 'crystal_mountain_dr',
    props: {
      projectId: 2,
      projectName: "Crystal Mountain Dr.",
      projectLocation: "Boone, NC",
      showLoc: true
    },
    component: () => import(/* webpackChunkName: "CrystalMtnDr" */ /* webpackPrefetch: true */ '../views/ProjectView.vue')
  },
  {
    path: '/portfolio/palladium_ct',
    name: 'palladium_ct',
    props: {
      projectId: 3,
      projectName: "Palladium Ct.",
      projectLocation: "Appleton, WI",
      showLoc: false
    },
    component: () => import(/* webpackChunkName: "PalladiumCt" */ /* webpackPrefetch: true */ '../views/ProjectView.vue')
  },
  {
    path: '/portfolio/north_appleton',
    name: 'north_appleton',
    props: {
      projectId: 4,
      projectName: "North Appleton",
      projectLocation: "Appleton, WI",
      showLoc: false
    },
    component: () => import(/* webpackChunkName: "NorthAppleton" */ /* webpackPrefetch: true */ '../views/ProjectView.vue')
  },
  {
    path: '/portfolio/client_first',
    name: 'client_first',
    props: {
      projectId: 5,
      projectName: "Client First Tax & Wealth Advisors",
      projectLocation: "West Bend, WI",
      showLoc: true
    },
    component: () => import(/* webpackChunkName: "ClientFirst" */ /* webpackPrefetch: true */ '../views/ProjectView.vue')
  },
]

const router = new VueRouter({
  scrollBehavior: function (to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { el: to.hash, behavior: "smooth" };
    } else {
      window.scrollTo(0, 0);
    }
 },
  routes,
  mode: 'history'
})

export default router;
