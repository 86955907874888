import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: { 
            minifyTheme: function (css) {
                return process.env.NODE_ENV === 'production'
                  ? css.replace(/[\r\n|\r|\n]/g, '') // remove \s
                  : css
              },
         },
        themes: {
            light: {
                primary: '#000000',
                secondary: '#FFFFFF',
                accent: colors.red.lighten3
            }
        }
    }
});
